'use strict';

/* globals moment angular _ */
(function () {
    // Store details controller
    angular.module('stores.store-details').controller('StoreDetailsScheduleController', StoreDetailsScheduleController);

    StoreDetailsScheduleController.$inject = ['$interval', 'storeResolve', 'SchedulingService', 'Authentication', 'DetailsEditService', 'uiGridConstants', '$mdDialog', 'Stores', '$mdPanel', '$anchorScroll', 'ErrorService'];

    function StoreDetailsScheduleController($interval, store, schedulingService, Authentication, DetailsEditService, uiGridConstants, $mdDialog, stores, $mdPanel, $anchorScroll, ErrorService) {

        // ui-grid bug workaround
        // https://github.com/angular-ui/ui-grid/issues/4514
        function template(header) {
            return '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ui-grid-one-bind-aria-labelledby-grid="col.uid + \'-header-text \' + col.uid + \'-sortdir-text\'" aria-sort="{{col.sort.direction == asc ? \'ascending\' : ( col.sort.direction == desc ? \'descending\' : (!col.sort.direction ? \'none\' : \'other\'))}}"><div ng-if="!grid.appScope.scheduleEdit"><div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" title="TOOLTIP"><span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + \'-header-text\'">{{ grid.appScope.store.labels.' + header + ' CUSTOM_FILTERS }}</span><span ui-grid-one-bind-id-grid="col.uid + \'-sortdir-text\'" ui-grid-visible="col.sort.direction" aria-label="{{getSortDirectionAriaLabel()}}"><i ng-class="{ \'ui-grid-icon-up-dir\': col.sort.direction == asc, \'ui-grid-icon-down-dir\': col.sort.direction == desc, \'ui-grid-icon-blank\': !col.sort.direction }" title="{{isSortPriorityVisible() ? i18n.headerCell.priority + \' \' + ( col.sort.priority + 1 )  : null}}" aria-hidden="true"></i><sub ui-grid-visible="isSortPriorityVisible()" class="ui-grid-sort-priority-number">{{col.sort.priority + 1}}</sub></span></div><div role="button" tabindex="0" ui-grid-one-bind-id-grid="col.uid + \'-menu-button\'" class="ui-grid-column-menu-button" ng-if="grid.options.enableColumnMenus && !col.isRowHeader  && col.colDef.enableColumnMenu !== false" ng-click="toggleMenu($event)" ng-class="{\'ui-grid-column-menu-button-last-col\': isLastCol}" ui-grid-one-bind-aria-label="i18n.headerCell.aria.columnMenuButtonLabel" aria-haspopup="true"><i class="ui-grid-icon-angle-down" aria-hidden="true">&nbsp;</i></div><div ui-grid-filter></div></div><div ng-if="grid.appScope.scheduleEdit" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex"><input style="width: 100%;" ng-model="grid.appScope.edit.colHeaders.' + header + '"></div></div>';
        }

        function viewColDefs() {
            return [{
                name: 'Day',
                field: '_id.day',
                cellClass: weekCellClass,
                width: 65,
                headerCellClass: 'default-cell-header',
                cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD | dateIntFormat:\'ddd (D)\'}}</div>'
            }, {
                name: 'Open',
                field: 'openTime',
                width: 70,
                cellClass: weekCellClass,
                headerCellClass: 'default-cell-header',
                cellTemplate: 'modules/stores/features/store-details/schedule/schedule-time.client.view.html'
            }, {
                name: 'Close',
                field: 'closeTime',
                width: 70,
                cellClass: weekCellClass,
                headerCellClass: 'default-cell-header',
                cellTemplate: 'modules/stores/features/store-details/schedule/schedule-time.client.view.html'
            }, {
                name: 'primary',
                field: 'assignments.primary',
                cellClass: weekCellClass,
                headerCellClass: 'primary-contact-cell-header',
                headerCellTemplate: template('primary'),
                cellTemplate: 'modules/stores/features/store-details/schedule/schedule-primary.client.view.html'
            }, {
                name: 'First Backup',
                field: 'assignments.secondary',
                cellClass: weekCellClass,
                headerCellClass: 'first-backup-contact-cell-header',
                headerCellTemplate: template('secondary'),
                cellTemplate: 'modules/stores/features/store-details/schedule/schedule-secondary.client.view.html'
            }, {
                name: 'Second Backup',
                field: 'assignments.tertiary',
                cellClass: weekCellClass,
                headerCellClass: 'second-backup-contact-cell-header',
                headerCellTemplate: template('tertiary'),
                cellTemplate: 'modules/stores/features/store-details/schedule/schedule-tertiary.client.view.html'
            }, {
                name: 'Third Backup',
                field: 'assignments.quaternary',
                cellClass: weekCellClass,
                headerCellClass: 'third-backup-contact-cell-header',
                headerCellTemplate: template('quaternary'),
                cellTemplate: 'modules/stores/features/store-details/schedule/schedule-quaternary.client.view.html'
            }, {
                name: 'Final Contact',
                field: 'assignments.final',
                cellClass: weekCellClass,
                headerCellClass: 'final-contact-cell-header',
                headerCellTemplate: template('final'),
                cellTemplate: 'modules/stores/features/store-details/schedule/schedule-final.client.view.html'
            }, {
                name: 'Notes',
                field: 'notes',
                cellClass: weekCellClass,
                headerCellClass: 'default-cell-header',
                cellTemplate: 'modules/stores/features/store-details/schedule/schedule-notes.client.view.html'
            },
            // copy column goes here when in edit mode (see editColDefs)
            {
                name: '',
                field: 'update',
                width: 42,
                cellClass: weekCellClass,
                headerCellClass: 'default-cell-header',
                cellTemplate: 'modules/stores/features/store-details/schedule/schedule-updated-by.client.view.html'
            }];
        }

        /*jshint validthis: true */
        var vm = this;
        var authentication = Authentication;
        var initialPageLoad = true;

        var editColDefs = viewColDefs();
        editColDefs.splice(-1, 0, {
            name: 'Copy',
            width: 25,
            cellClass: weekCellClass,
            headerCellClass: 'default-cell-header copy-button-header',
            cellTemplate: 'modules/stores/features/store-details/schedule/schedule-copy-button.client.view.html'
            // cellTemplate: '<div class="ui-grid-cell-contents schedule-icon-cell"><span class="glyphicons glyphicons-arrow-down" ng-click="grid.appScope.pushAssignments(row)"></span></div>'
        });

        //exposed functions
        vm.showIcon = showIcon;
        vm.moveWeek = moveWeek;
        vm.startEdit = startEdit;
        vm.cancelEdit = cancelEdit;
        vm.save = save;
        vm.getDirMatches = getDirMatches;
        vm.pushAssignments = pushAssignments;
        vm.asMessage = asMessage;
        vm.clearContact = clearContact;
        vm.showGridPopUp = showGridPopUp;
        vm.isWeekEmpty = isWeekEmpty;
        vm.scheduleAsNeeded = scheduleAsNeeded;

        //bindable members go here
        vm.store = store;
        vm.storeSchedule = null;
        vm.error = null;
        vm.storeDay = moment();
        vm.day = schedulingService.getCurrentActiveScheduleDay(vm.store);
        vm.weekStart = moment().startOf('isoWeek').toDate();
        vm.weekEnd = moment().endOf('isoWeek').toDate();
        vm.scheduleEdit = false;
        vm.isStoreOpen = null;

        vm.edit = {};

        vm.getGlobalEditMode = DetailsEditService.getGlobalEditMode;
        vm.setGlobalEditMode = DetailsEditService.setGlobalEditMode;

        vm.scheduleGridOptions = {
            columnDefs: viewColDefs(),
            enableCellEdit: false,
            enableHorizontalScrollbar: 0,
            enableVerticalScrollbar: 0,
            enableSorting: false,
            enableColumnMenus: false,
            rowHeight: 40,
            onRegisterApi: function onRegisterApi(api) {
                vm.gridApi = api;
            },
            appScopeProvider: vm
        };

        //initialize the controller
        activate();

        //function declarations go here
        function activate() {
            vm.day.then(function (day) {
                var scheduleDay = schedulingService.dayIntToDate(day);
                vm.day = day;
                vm.storeDay = moment(scheduleDay);
                vm.weekStart = moment(scheduleDay).startOf('isoWeek').toDate();
                vm.weekEnd = moment(scheduleDay).endOf('isoWeek').toDate();
                vm.saturday = moment(scheduleDay).endOf('isoWeek').toDate();
                vm.sunday = moment(scheduleDay).endOf('isoWeek').toDate();
                loadSchedule(scheduleDay);
            });

            // Refresh the grid every second so we can reset the class to show if store is open or closed
            schedulingService.storeIsOpen(vm.store).then(function (isOpen) {
                vm.isStoreOpen = isOpen;
            });
            $interval(function () {
                schedulingService.storeIsOpen(vm.store).then(function (isOpen) {
                    vm.isStoreOpen = isOpen;
                });
                vm.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
            }, 60000);
        }

        function showIcon(iconId) {
            switch (iconId) {
                case 'edit':
                    return !vm.scheduleEdit && authentication.user.canEdit;
                case 'cancel':
                case 'save':
                    return vm.scheduleEdit && authentication.user.canEdit;
                default:
                    return false;
            }
        }

        function startEdit() {
            vm.setGlobalEditMode(true);
            vm.edit.schedule = angular.copy(vm.storeSchedule);
            vm.edit.colHeaders = angular.copy(vm.store.labels);
            vm.scheduleGridOptions.columnDefs = editColDefs;
            setGridData(vm.edit.schedule);
            vm.scheduleEdit = true;
        }

        function endEdit() {
            vm.scheduleGridOptions.columnDefs = viewColDefs();
            setGridData(vm.storeSchedule);
            delete vm.edit.schedule;
            delete vm.edit.colHeaders;
            vm.scheduleEdit = false;
            vm.setGlobalEditMode(false);
        }

        function cancelEdit() {
            vm.setGlobalEditMode(false);
            endEdit();
        }

        function save() {

            console.log("saving...");

            var storeUpdateBody = { labels: vm.edit.colHeaders };
            var scheduleUpdateBody = vm.edit.schedule;

            console.log(scheduleUpdateBody);

            stores.updateStore(vm.store._id.toString(), {
                labels: vm.edit.colHeaders
            }, function (store) {

                console.log('Store update response:', store);

                vm.store = store;
                schedulingService.updateSchedule(vm.store._id, vm.edit.schedule, function (r) {
                    vm.storeSchedule = r;

                    vm.storeSchedule = vm.storeSchedule.filter(function (day) {
                        return day.assignments && Object.values(day.assignments).some(function (v) {
                            return v !== null;
                        });
                    });

                    console.log(vm.storeSchedule);

                    schedulingService.getScheduleUpdateStatus(vm.store).$promise.then(function (data) {
                        vm.store.updateStatus = data;
                        vm.store.updateStatus.class = schedulingService.scheduleUpdateStatusClasses[data.status];
                        endEdit();
                    });
                }, function (scheduleErr) {
                    console.error('error updating schedule');
                    console.error(scheduleErr);
                    ErrorService.emailClientError({ type: 'Update Schedule Err', store: vm.store, updateBody: scheduleUpdateBody, err: scheduleErr });
                });
            }, function (storeErr) {
                console.error('error updating store');
                console.error(storeErr.data && storeErr.data.message ? storeErr.data.message : storeErr);
                ErrorService.emailClientError({ type: 'Update Store Err', store: vm.store, updateBody: storeUpdateBody, err: storeErr });
            });
        }

        function isWeekEmpty(schedule) {
            var emptyDayCount = 0;
            var promises = [];
            schedule.forEach(function (day) {
                if (!day.assignments && !day.update) {
                    emptyDayCount++;
                    if (emptyDayCount === 7) {
                        promises.push(scheduleAsNeeded());
                        return true;
                    }
                }
            });
            return Promise.all(promises);
        }

        function scheduleAsNeeded() {
            var weekStart = moment(vm.weekStart).startOf('isoWeek');
            weekStart = weekStart.toDate();

            var scheduleDays = ''; // Comma separated string of schedule days;
            vm.storeSchedule.forEach(function (day) {
                scheduleDays = scheduleDays + day._id.day.toString() + ',';
            });

            return schedulingService.getScheduleAsNeeded(vm.store, weekStart, scheduleDays).$promise.then(function (data) {
                vm.storeSchedule = data;
                console.log(vm.storeSchedule);
                setGridData(vm.storeSchedule);
            });
        }

        function moveWeek(value) {
            var weekStart = moment(vm.weekStart).add(value, 'week').startOf('isoWeek');
            vm.weekStart = weekStart.toDate();
            vm.weekEnd = weekStart.endOf('isoWeek').toDate();
            loadSchedule(vm.weekStart);
        }

        function loadSchedule(targetDate) {
            schedulingService.getSchedule(vm.store, targetDate).then(function (data) {
                vm.storeSchedule = data;

                if (vm.storeSchedule.length > 7) {
                    vm.storeSchedule = data.filter(function (day) {
                        return day.assignments && Object.values(day.assignments).some(function (v) {
                            return v !== null;
                        });
                    });
                }

                //Check if the schedule is empty and duplicate previous weeks schedule.
                var promise = void 0;
                if (vm.store.updatesAsNeeded === true) {
                    promise = isWeekEmpty(data);
                } else {
                    promise = Promise.resolve(null);
                }

                promise.then(function () {
                    setGridData(vm.storeSchedule);
                    // pop the first one on page load
                    if (initialPageLoad) {
                        var today = vm.storeDay.isoWeekday() - 1;
                        var hasPrimaryContact = vm.storeSchedule[today].assignments && vm.storeSchedule[today].assignments.primary;
                        if (hasPrimaryContact) {
                            //showGridPopUp('Primary Contact', vm.scheduleGridOptions.data[today].assignments.primary);
                        } else {
                                //showGridPopUp('nocontact');
                            }
                        initialPageLoad = false;
                    }
                });
            });
        }

        function setGridData(data) {
            vm.scheduleGridOptions.data = data;
            vm.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
        }

        function getDirMatches(search) {
            search = search.toLowerCase();
            var matches = [];
            _.forEach(vm.store.directory, function (d) {
                if (d.name && d.name.toLowerCase().includes(search)) {
                    matches.push(d);
                }
            });
            return matches;
        }

        function pushAssignments(row) {
            var idx = vm.edit.schedule.indexOf(row.entity);
            for (var i = idx; i < vm.edit.schedule.length; i++) {
                vm.edit.schedule[i].assignments = angular.copy(row.entity.assignments);
            }
        }

        function asMessage(text, row, col) {
            var autocompletes = Array.from(document.getElementsByClassName('schedule-auto')).map(function (a) {
                return angular.element(a);
            });
            autocompletes.forEach(function (a) {
                a.scope().$$childHead.$mdAutocompleteCtrl.hidden = true;
            });
            vm.edit.schedule[vm.edit.schedule.indexOf(row.entity)].assignments[col] = { message: text };
        }

        function clearContact(row, col) {
            vm.edit.schedule[vm.edit.schedule.indexOf(row.entity)].assignments[col] = null;
        }

        function showGridPopUp(type, dataObj) {
            schedulingService.storeIsOpen(vm.store).$promise.then(function (isOpen) {
                if (type === 'nocontact') {
                    $mdDialog.show({
                        clickOutsideToClose: true,
                        templateUrl: 'modules/stores/features/store-details/schedule/popup/no-contact.client.view.html',
                        parent: angular.element(document.body),
                        bindToController: true,
                        locals: { store: vm.store },
                        controller: function controller() {
                            var vm = this;
                            vm.done = $mdDialog.cancel;
                        },
                        controllerAs: 'popup'
                    }).finally(function () {
                        $anchorScroll();
                    });
                } else if (initialPageLoad && isOpen) {
                    $mdDialog.show({
                        clickOutsideToClose: true,
                        locals: {
                            store: vm.store
                        },
                        templateUrl: 'modules/stores/features/store-details/schedule/popup/open-store.client.view.html',
                        parent: angular.element(document.body),
                        bindToController: true,
                        controller: function controller() {
                            var vm = this;
                            vm.done = $mdDialog.cancel;
                        },
                        controllerAs: 'popup'
                    }).finally(function () {
                        $anchorScroll();
                    });
                } else {
                    var employee = {};
                    stores.resource.get({
                        storeId: vm.store._id
                    }).$promise.then(function (freshStore) {
                        employee = dataObj;
                        angular.forEach(freshStore.directory, function (storeEmp, key) {
                            if (storeEmp._id === employee._id) {
                                employee = storeEmp;
                            }
                        });

                        if (event && event._id) {
                            employee = freshStore.directory[_.findIndex(freshStore.directory, { '_id': event._id })];
                        }
                        if (!vm.scheduleEdit) {
                            $mdDialog.show({
                                locals: { directorian: employee, type: type, store: vm.store },
                                templateUrl: 'modules/stores/features/store-details/schedule/popup/directory-details.view.html',
                                parent: angular.element(document.body),
                                clickOutsideToClose: true,
                                fullscreen: false,
                                bindToController: true,
                                controllerAs: 'grid',
                                controller: function controller() {
                                    var vm = this;
                                    vm.cancel = $mdDialog.cancel;
                                }
                            }).finally(function () {
                                $anchorScroll();
                            });
                        }
                    });
                }
            });
        }

        //EBS: put the red border around the active day
        function weekCellClass(grid, row, col, rowRenderIndex, colRenderIndex) {
            var classes = [
            /*0*/'scheduling-plan-cell',
            /*1*/'scheduling-plan-cell-clickable',
            /*2*/'scheduling-plan-cell-clickable',
            /*3*/'primary-contact',
            /*4*/'first-contact',
            /*5*/'second-contact',
            /*6*/'third-contact',
            /*7*/'final-contact',
            /*8*/'scheduling-plan-cell-clickable',
            /*9*/''];
            var baseClass = classes[colRenderIndex];

            if (vm.day === row.entity._id.day) {
                var prefix = 'active';
                if (vm.isStoreOpen === true) {
                    prefix = 'open';
                } else if (vm.isStoreOpen === false) {
                    prefix = 'closed';
                }

                baseClass += ' ' + prefix + '-day';
                if (colRenderIndex === 0) {
                    baseClass += ' ' + prefix + '-day-left';
                } else if (colRenderIndex === grid.columns.length - 1) {
                    baseClass += ' ' + prefix + '-day-right';
                }
            }

            return baseClass;
        }
    }
})();