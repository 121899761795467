'use strict';

(function () {
    // Users service used for communicating with the users REST endpoint
    angular.module('stores').factory('SchedulingService', schedulingService);

    schedulingService.$inject = ['$resource', '$rootScope', '$filter', '$q'];

    function schedulingService($resource, $rootScope, $filter, $q) {
        var scheduleResource = $resource('stores/:storeId/schedule', { date: moment().format('GGGG-WW') }, {
            update: { method: 'PUT', isArray: true },
            get: {
                method: 'GET',
                isArray: true
            },
            getUpdateStatus: {
                url: '/stores/:storeId/scheduleUpdateStatus',
                method: 'GET',
                isArray: false,
                cancellable: true
            },
            getScheduleAsNeeded: {
                url: '/stores/:storeId/scheduleAsNeeded',
                method: 'GET',
                isArray: true
            }
        });

        var scheduleCache = {};

        var scheduleSvc = {
            updateSchedule: updateSchedule,
            getSchedule: getSchedule,
            getScheduleAsNeeded: getScheduleAsNeeded,
            getScheduleUpdateStatus: getScheduleUpdateStatus,
            scheduleUpdateStatusClasses: {
                good: 'text-success',
                overdue: 'text-danger',
                'as needed': 'text-muted'
            },
            getCurrentActiveScheduleDay: getCurrentActiveScheduleDay,
            resource: scheduleResource,
            storeIsOpen: storeIsOpen,
            dayIntToDate: dayIntToDate,
            dateToDayInt: dateToDayInt
        };

        return scheduleSvc;

        function updateSchedule(storeId, newSchedule, success, err) {
            function successWrapper(r) {
                $rootScope.$broadcast('schedule-update', { schedule: r });
                var week = dayIntToDate(r[0]._id.day, true).format('GGGG-WW');
                if (isCached(storeId, week)) {
                    //update cache with new schedule
                    console.log("is cached");
                    scheduleCache[storeId][week] = r;
                } else {
                    console.log("isn't cached");
                }
                if (success) {
                    console.log("success");
                    success(r);
                }
            }

            scheduleResource.update({ storeId: storeId }, newSchedule, successWrapper, err);
        }

        function getSchedule(store, targetDate) {
            var targetWeek = moment(targetDate).format('GGGG-WW'),
                storeId = store._id;

            return getCachedSchedule(storeId, targetWeek);
        }

        function getScheduleAsNeeded(store, targetDate, scheduleDays) {
            var targetWeek = moment(targetDate).format('GGGG-WW'),
                storeId = store._id;

            return scheduleResource.getScheduleAsNeeded({ storeId: storeId, date: targetWeek, scheduleDays: scheduleDays });
        }

        function getScheduleUpdateStatus(store, statusFromDate) {
            statusFromDate = statusFromDate || moment().startOf('day').format('YYYY-MM-DD');
            return scheduleResource.getUpdateStatus({ storeId: store._id, now: statusFromDate });
        }

        function getCurrentActiveScheduleDay(store) {

            var timezone = store.timezone || moment.tz.guess(),
                storeNow = moment().tz(timezone),
                storeDay = dateToDayInt(storeNow),
                storeIsWeekday = storeNow.isoWeekday() < 6,
                storeIsSaturday = storeNow.isoWeekday() === 6,
                storeIsSunday = storeNow.isoWeekday() === 7,
                schedule = getSchedule(store, storeNow).then(findScheduleDay);

            return schedule;

            function findScheduleDay(schedule) {
                var hasOpenTime = store.defaultTimes && (storeIsWeekday ? store.defaultTimes.weekday && store.defaultTimes.weekday.openTime !== null : store.defaultTimes.saturday && store.defaultTimes.saturday.openTime !== null || store.defaultTimes.sunday && store.defaultTimes.sunday.openTime !== null),
                    curScheduleItem = _.find(schedule, function (s) {
                    return s._id.day === storeDay;
                }),
                    hasScheduleItem = curScheduleItem && curScheduleItem._id,
                    defaultOpenTime = null;

                if (hasOpenTime) {
                    if (storeIsWeekday) {
                        defaultOpenTime = store.defaultTimes.weekday.openTime;
                    } else if (storeIsSaturday) {
                        defaultOpenTime = store.defaultTimes.saturday.openTime;
                    } else if (storeIsSunday) {
                        defaultOpenTime = store.defaultTimes.sunday.openTime;
                    } else {
                        defaultOpenTime = null;
                    }
                } else {
                    defaultOpenTime = null;
                }

                var openTime = hasScheduleItem && curScheduleItem.openTime || defaultOpenTime;
                if (openTime == null) {
                    //unknown opening time, return today
                    return storeDay;
                }

                var splitTime = parseTimeTo24h(openTime);
                splitTime = splitTime ? splitTime.split(':') : null;
                var openHour = splitTime && splitTime.length ? splitTime[0] : null;
                var openMinute = splitTime && splitTime.length > 1 ? splitTime[1] : null;
                var openTime2 = dayIntToDate(curScheduleItem._id.day, true).tz(timezone).hour(openHour).minute(openMinute);
                if (storeNow > openTime2) {
                    return storeDay;
                } else {
                    return getSchedule(store, dayIntToDate(--storeDay)).$promise.then(findScheduleDay);
                }
            }
        }

        function isCached(storeId, targetWeek) {
            if (!scheduleCache[storeId]) {
                scheduleCache[storeId] = {};
            }
            if (!scheduleCache[storeId][targetWeek]) {
                return false;
            }
            return true;
        }

        function getCachedSchedule(storeId, targetWeek) {
            if (!isCached(storeId, targetWeek)) {
                return scheduleResource.get({ storeId: storeId, date: targetWeek }).$promise.then(function (schedule) {
                    scheduleCache[storeId][targetWeek] = schedule;
                    return schedule;
                });
            }

            return $q.resolve(scheduleCache[storeId][targetWeek]);
        }

        /**
         * returns true if store is currently open
         * if no scheduled open time, uses store default open time
         * if no default open time, returns false
         */
        function storeIsOpen(store) {
            var storeDefaultTimes = store.defaultTimes;
            if (!storeDefaultTimes) {
                storeDefaultTimes = { weekday: { openTime: null, closeTime: null }, saturday: { openTime: null, closeTime: null }, sunday: { openTime: null, closeTime: null } };
            }

            var timezone = store.timezone || moment.tz.guess();
            var currentStoreTime = moment.tz(timezone);
            var activeScheduleDay = dateToDayInt(currentStoreTime);

            var week = currentStoreTime.format('GGGG-WW');
            return getCachedSchedule(store._id, week).then(function (schedule) {
                var isWeekday = currentStoreTime.isoWeekday() < 6;
                var isSaturday = currentStoreTime.isoWeekday() === 6;
                var isSunday = currentStoreTime.isoWeekday() === 7;
                var scheduleEntry = _.find(schedule, function (s) {
                    return s._id.day === activeScheduleDay;
                });
                var openTime = null;
                var closeTime = null;

                if (scheduleEntry) {
                    if (isWeekday) {
                        openTime = scheduleEntry.openTime || storeDefaultTimes.weekday.openTime;
                        closeTime = scheduleEntry.closeTime || storeDefaultTimes.weekday.closeTime;
                    } else if (isSaturday) {
                        openTime = scheduleEntry.openTime || storeDefaultTimes.saturday.openTime;
                        closeTime = scheduleEntry.closeTime || storeDefaultTimes.saturday.closeTime;
                    } else if (isSunday) {
                        openTime = scheduleEntry.openTime || storeDefaultTimes.sunday.openTime;
                        closeTime = scheduleEntry.closeTime || storeDefaultTimes.sunday.closeTime;
                    }
                    openTime = $filter('convertMilitaryTime')(openTime);
                    openTime = openTime ? openTime.toLowerCase().trim() : null;
                    closeTime = $filter('convertMilitaryTime')(closeTime);
                    closeTime = closeTime ? closeTime.toLowerCase().trim() : null;
                }

                if (!openTime || !closeTime) {
                    //we need both of these to answer
                    return null;
                } else {
                    openTime = /am/i.test(openTime) ? openTime : openTime + ' am';
                    closeTime = /pm/i.test(closeTime) ? closeTime : closeTime + ' pm';
                }

                openTime = parseTimeTo24h(openTime);
                openTime = openTime ? openTime.split(':') : null;
                closeTime = parseTimeTo24h(closeTime);
                closeTime = closeTime ? closeTime.split(':') : null;
                if (!openTime || !closeTime) {
                    return null;
                }

                var storeOpenTime = currentStoreTime.clone().hour(openTime[0]).minute(openTime[1]);
                var storeCloseTime = currentStoreTime.clone().hour(closeTime[0]).minute(closeTime[1]);

                return storeOpenTime < currentStoreTime && currentStoreTime < storeCloseTime;
            });
        }

        function dateToDayInt(date) {
            if (date.year) {
                //moment object
                var _dateInt = date.year() * 10000 + date.month() * 100 + date.date();
                return _dateInt;
            }
            var dateInt = date.getFullYear() * 10000 + date.getMonth() * 100 + date.getDay();
            return dateInt;
        }

        function dayIntToDate(day, asMoment) {
            var r = moment().year(day / 10000).month(day % 10000 / 100).date(day % 100);
            if (asMoment) {
                return r;
            }
            return r.toDate();
        }

        /**
         * parses a string to return 24h time
         * returns "0:00" for 'closed' or otherwise unparseable times
         * expects h?h:mm ((a|p)m?)?
         * e.g. 7:30p, 07:30 pm, 19:30
         */
        function parseTimeTo24h(timeString) {
            if (!timeString) {
                return null;
            }

            timeString = $filter('convertMilitaryTime')(timeString);
            timeString = timeString.toLowerCase().trim();

            var pattern = /^([0-2]?[\d]):([0-5][0-9])\s?([a|p]m?)?/i;
            var matches = timeString.match(pattern);
            if (!matches) {
                return null;
            }

            var hour = parseInt(matches[1]),
                min = parseInt(matches[2]);

            if (matches[3]) {
                var ap = matches[3].toLowerCase().substr(0, 1);
                if (ap === 'p' && hour !== 12) {
                    //pm but not noon
                    hour += 12;
                } else if (ap === 'a' && hour === 12) {
                    //midnight
                    hour = 0;
                }
            }

            return 'h:m'.replace('h', hour).replace('m', zeroPad(min));

            function zeroPad(num) {
                var ret = '00' + num;
                return ret.substr(ret.length - 2);
            }
        }
    }
})();